/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import GMap from './GMap';
import '../../App.css';
import { Checkbox, Col, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
// API key of the google map
// const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
 
// load google map script
// const loadGoogleMapScript = (callback) => {
// 	if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
// 		callback();
// 	} else {
// 		try{

// 			const googleMapScript = document.createElement('script');
// 			googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=geometry`;
// 			window.document.body.appendChild(googleMapScript);
// 			googleMapScript.addEventListener('load', callback);
// 		}catch(e){
// 			console.log(e);

// 		}
// 	}
// };
 
const Map = (props) => {
	const {onClick } = props;

	// const [loadMap, setLoadMap] = useState(false);
	const [changePipe, setChangePipe] = useState(true);
	const [changeBorder, setChangeBorder] = useState(false);
	const [changeFiber, setChangeFiber] = useState(false);
	const [changeKMP, setChangeKMP] = useState(false);
	const [changeCH, setChangeCH] = useState(false);
	const [changeWB, setChangeWB] = useState(false);
	const [changeTP, setChangeTP] = useState(false);
	const [changeTLP, setChangeTLP] = useState(props.TLPData=='TLPData' ? true: false);
	const [changeEvent, setChangeEvent] = useState(true);
	const [changeAlarm, setChangeAlarm] = useState(true);

	const [changeOptionalPipe, setOptionalChangePipe] = useState(false);

	const [ changeinspectionLine, setInspectionLine] =  useState(true);
	const [changeinspectionPoint ,setInspectionPoint] = useState(false);
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabeluper = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.PIPELINE : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.AIRPORT : oneviewType.toLowerCase() == 'border' ? langdata && langdata.BORDER : 'pipeline';

	useEffect(() => {
		setChangeEvent(props.filterDrawer === true ? false : true);
		setChangeAlarm(props.alarmfilterDrawer === true ? false : true);
	}, [props.filterDrawer, props.alarmfilterDrawer]);

	const onChangePipe = () => {
		changePipe === true ? setChangePipe(false) : setChangePipe(true);
	};

	const onChangeBorder = () => {
		changeBorder === true ? setChangeBorder(false) : setChangeBorder(true);
	};

	const onChangeFiber = () => {
		changeFiber === true ? setChangeFiber(false) : setChangeFiber(true);
	};

	const onChangeKMP = () => {
		changeKMP === true ? setChangeKMP(false) : setChangeKMP(true);
	};

	const onChangeCH = () => {
		changeCH === true ? setChangeCH(false) : setChangeCH(true);

	};

	const onchangeWB = () => {
		changeWB === true ? setChangeWB(false) : setChangeWB(true);

	};

	const onchangeTP = () => {
		changeTP === true ? setChangeTP(false) : setChangeTP(true);

	};

	const onchangeTLP = () => {
		changeTLP === true ? setChangeTLP(false) : setChangeTLP(true);
	};

	const onChangeEvnt = () => {
		changeEvent === true ? setChangeEvent(false) : setChangeEvent(true);
	};

	const onChangeAlarm = () => {
		changeAlarm === true ? setChangeAlarm(false) : setChangeAlarm(true);
	};

	const onChangeOptionalPipe = () => {
		changeOptionalPipe === true ? setOptionalChangePipe(false) : setOptionalChangePipe(true);
	};

	const onChangeInspectionLine = () => {
		changeinspectionLine === true ? setInspectionLine(false) : setInspectionLine(true);
	};

	const onChangeInspectionPoint = () => {
		changeinspectionPoint === true ? setInspectionPoint(false) : setInspectionPoint(true);
	};
 
	return (
		<Content>
			{props.eventDetailsData || props.alarmDetailsData || props.TLPDetails ? null :
				<Row span={24} style={{ color:'#000000a6', margin : '10px'}}>
					<Col span={24}>
						<Checkbox style={{ color:'#000000a6', alignContent : 'left'}} onChange={onChangeFiber}>{langdata && langdata.FIBER ? langdata.FIBER: 'FIBER'}</Checkbox>
						<Checkbox style={{ color:'#000000a6'}} defaultChecked onChange={onChangePipe}>{oneviewtypeLabeluper ? oneviewtypeLabeluper: 'PIPELINE'}</Checkbox>
						{dashboardtype !=='tlp' && props.TLPData !== 'TLPData' ?
							<Checkbox style={{ color:'#000000a6'}} onChange={onChangeOptionalPipe}>{langdata && langdata.OPTIONALPATH ? langdata.OPTIONALPATH: 'OPTIONAL PATH'}</Checkbox>
							: null }
						<Checkbox style={{ color:'#000000a6'}} onChange={onChangeBorder}>{langdata && langdata.PIPELINEBORDER ? langdata.PIPELINEBORDER: 'PIPELINE BORDER'}</Checkbox>
						{ props.eventData ? 
							<Checkbox style={{ color:'#000000a6'}} checked = {props.filterDrawer === true ? false : changeEvent} onChange={onChangeEvnt}>{langdata && langdata.EVENTS ? langdata.EVENTS: 'EVENTS'}</Checkbox>
							: null	
						}
						{ props.alarmData ? 
							<Checkbox style={{ color:'#000000a6'}} checked = {props.alarmfilterDrawer === true ? false : changeAlarm} onChange={onChangeAlarm}>{langdata && langdata.ALARMS ? langdata.ALARMS: 'ALARMS'}</Checkbox>
							: null	
						}
						<Checkbox style={{ color:'#000000a6'}} onChange={onChangeKMP}>{langdata && langdata.KMP ? langdata.KMP: 'KMP'}</Checkbox>
						<Checkbox style={{ color:'#000000a6'}} onChange={onChangeCH}>{langdata && langdata.CH ? langdata.CH: 'CH'}</Checkbox>
						<Checkbox style={{ color:'#000000a6'}} onChange={onchangeWB}>{langdata && langdata.WB ? langdata.WB: 'WB'}</Checkbox>
						<Checkbox style={{ color:'#000000a6'}} onChange={onchangeTP}>{langdata && langdata.TURNINGPOINT ? langdata.TURNINGPOINT: 'TURNING POINT'}</Checkbox>
						<Checkbox style={{ color:'#000000a6'}} defaultChecked={props.TLPData=='TLPData' ?  true : false} onChange={onchangeTLP}>{langdata && langdata.TLP ? langdata.TLP: 'TLP'}</Checkbox>
						{props.inspectionData || props.patch_data || props.vulnerable_data ?
							<>
								<Checkbox style={{ color:'#000000a6'}} defaultChecked onChange={onChangeInspectionLine}>{langdata && langdata.LINE ? langdata.LINE: 'LINE'}</Checkbox>
								<Checkbox style={{ color:'#000000a6'}} onChange={onChangeInspectionPoint}>{langdata && langdata.POINTS ? langdata.POINTS: 'POINTS'}</Checkbox></> :
							null}
					</Col>
				</Row>
			}
			<GMap changePipe = {changePipe} changeBorder = {changeBorder} changeFiber = { changeFiber} changeKMP = {changeKMP}
				changeCH = {changeCH}  changeWB = {changeWB} changeTP = {changeTP} changeTLP = {changeTLP} eventData = {props.eventData} changeEvent = {changeEvent} linewalkerData={props.linewalkerData}
				changeOptionalPipe = {changeOptionalPipe} inspectionData = {props.inspectionData} changeinspectionLine= {changeinspectionLine}  changeinspectionPoint= {changeinspectionPoint}
				pigData={props.pigData} pidsData ={props.pidsData && props.pidsData}
				regionId={props.regionId ? props.regionId :null} startCh={props.startCh || props.startCh === 0 ? props.startCh : null } endCh={props.endCh || props.endCh === 0 ? props.endCh : null}
				onClick = {onClick} 
				alarmData = {props.alarmData} changeAlarm = {changeAlarm} height = {props.height} eventDetails = {props.eventDetailsData} alarmDetails = {props.alarmDetailsData}
				patch_data = {props.patch_data} vulnerable_data = {props.vulnerable_data} TLPDetails = {props.TLPDetails} TLPData={props.TLPData} pidsRegionData={props.regionPids} pigPids={props.pigPids}/>
			<br />
		</Content>
	);
};
 
export default Map;