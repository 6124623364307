import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Select, Typography, Button, Space, Table, DatePicker, Modal, Tooltip, message, Spin, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context';
import patchdata from './reducer';
import { PATCH_LIST, PATCH_LIST_ERROR, PATCH_LIST_SUCCESS } from './constants';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import {BatchDownloader} from './downloader';
import { DownloadOutlined, FileSearchOutlined,InfoOutlined } from '@ant-design/icons';
// import { enddatetimeCoverter, startdatetimeCoverter } from '../../datetime-converter';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';



const { Title } = Typography;

const { RangePicker } = DatePicker;

export default function Patch() {
	// const [getpatchdate, setPatchDate] = useState(new Date().toLocaleDateString('en-UK', {
	// 	day: '2-digit',
	// 	month: '2-digit',
	// 	year: 'numeric'
	// }));
	// console.log(getpatchdate);
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const initalState = {patch_data:[], details: null, pending: true, error: false };
	const [patch_data, dispatch] = useReducer(patchdata, initalState);
	const navigate = useNavigate();
	const [regionId, setRegionId] = useState(null);
	// const [dates, setDates] = useState(null);
	// const [value, setValue] = useState(null);
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const [url, setURL] = useState(null);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30));
	const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));
	const [allocationId, setAllocationId] = useState(null);
	// const [startCh, setStartCh] = useState(null);
	// const [endCh, setEndCh] = useState(null);
	// const disabledDate = () => {
	// 	if (!dates) {
	// 		return false;
	// 	}

	// };
	// const onOpenChange = (open) => {
	// 	if (open) {
	// 		setDates([null, null]);
	// 	} else {
	// 		setDates(null);
	// 	}
	// };

	// const regionparams = `&regioninfoId=${regionId}`;


	// const fromDate =  dates && dates ? new Date(dates[0]).toLocaleDateString('en-UK', {
	// 	day: '2-digit',
	// 	month: '2-digit',
	// 	year: 'numeric'
	// }):new Date().toLocaleDateString('en-UK', {
	// 	day: '2-digit',
	// 	month: '2-digit',
	// 	year: 'numeric'
	// });

	// const toDate =  dates && dates !=='1970-01-01' ? new Date(dates[1]).toLocaleDateString('en-UK', {
	// 	day: '2-digit',
	// 	month: '2-digit',
	// 	year: 'numeric'
	// }):new Date().toLocaleDateString('en-UK', {
	// 	day: '2-digit',
	// 	month: '2-digit',
	// 	year: 'numeric'
	// });


	const data = [];
	let columns = [];
	// const csvHeaders = [
	//   { id: 'patch_name', displayName: 'Patch Name' },
	//   { id: 'linewalker_name', displayName: 'Line Walker/NPV' },
	// ];

	let items = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	if (patch_data.patch_data && patch_data.patch_data) {
		if (patch_data.patch_data && patch_data.patch_data.length > 0) {
			columns = [
				{
					title: `${langdata && langdata.PatchName ? langdata.PatchName:'Patch Name'}`,
					dataIndex: 'allocation',
					key: 'allocation',
					fixed: 'left',
					width: 300,
					render(html) {
						return (<div><div dangerouslySetInnerHTML={{ __html: html }} /> </div>);
					},
				},
				{
					title: `${langdata && langdata.LineWalkerNPV ? langdata.LineWalkerNPV:'LineWalker/NPV'}`,
					dataIndex: 'name',
					key: 'name',
					fixed: 'left',
					width: 200,
				},
			];
		}

		patch_data.patch_data && patch_data.patch_data.map((item) => {
			items.push(item);
			if (item.userAllocation && !(data.find((x) => x.walkerid == item.userDetails.id))) {
    
				const tempdata={
					key: item.id,
					id: item.id,
					allocation: item.userAllocation && item.userAllocation ? `${item.userAllocation.pipelineConfigFrom} (${item.userAllocation.rouMarkerFrom}) - 
                ${item.userAllocation.pipelineConfigTo} (${item.userAllocation.rouMarkerTo})`:'',
					name:`${item.userDetails.firstName} ${item.userDetails.lastName}`,
					coverage: parseFloat(item.coverage).toFixed(2),
					itemAllocationId: item.allocationId,
					itemInspectionIds: item.inspectionIds,
					itemUserId: item.userId,
					pipelineId: item.pipelineId,
					walkerid: `${item.userDetails.id}`,
					coveredChainages:item.coveredChainages,
					chainageFrom :item.userAllocation.pipelineConfigFrom,
					chainageTo : item.userAllocation.pipelineConfigTo,
					regioninfoId: item.regioninfoId,
					date:item.date 

				};
				let totalaverage = 0;
				let totalCount = 0;
				let avg = 0;
				patch_data.patch_data && patch_data.patch_data.map((item2) => {
					if (item2.userDetails.id === item.userDetails.id) {
						tempdata[item2.date] = `${parseFloat(item2.coverage).toFixed(2)}%`;

						totalaverage = parseFloat(totalaverage) + parseFloat(item2.coverage);
						totalCount++;
					}
				});

				if (totalCount > 0) {
					avg = `${(parseFloat(totalaverage) / totalCount).toFixed(2)}%`;
				}

				tempdata.average = avg;
				data.push(tempdata);
			}
			const obj = columns.find((x) => x.title === item.date);
			if (obj === undefined) {
				const temp = {
					title: moment.utc(item.date).local().format(settingsdateformat),
					dataIndex: item.date,
					width:100,
					render: (text) => (
						<Space size="middle">
							<a title={text} onClick={()=>{showPatch(item, item.date);}}>{text}</a>
						</Space>
					),
					key: item.date,
				};
				// const tempcsv = {
				// 	id: item.date, displayName: item.date,
				// };
				// csvHeaders.push(tempcsv);
				columns.push(temp);
			}
		});
		const temp = {
			title: `${langdata && langdata.Average ? langdata.Average:'Average'}`,
			dataIndex: 'average',
			key: 'average',
			fixed: 'right',
			width: 100,
		};
		columns.push(temp);
		// const tempcsv = {
		// 	id: 'average', displayName: 'Average',
		// };
		// csvHeaders.push(tempcsv);
	}


	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	useEffect(() => {
		if(featuresModule.reportPatchCoverageEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 63);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			if(admin_roles.includes(permission)){
				getRegion(authtoken);
			}
			else{
				getUserAllocation(authtoken);
			}
			getPatch();
		}

	},[authtoken,regionId, startDate,endDate, allocationId]);



	const getPatch =()=>{

		dispatch({ type : PATCH_LIST});

		let allocationParams = '';
		if(admin_roles.includes(permission)){
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			allocationParams = allocationId ? `&allocationId=${allocationId}` : '';
		}

		setURL({base: `${api}/patchcoverage-list`,params:`date[$gte]=${moment(startDate).format('YYYY-MM-DD')}&date[$lte]=${moment(endDate).format('YYYY-MM-DD')}&$sort[date]=1${allocationParams}`});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/patchcoverage-list?date[$gte]=${moment(startDate).format('YYYY-MM-DD')}&date[$lte]=${moment(endDate).format('YYYY-MM-DD')}&$sort[date]=1${allocationParams}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : PATCH_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : PATCH_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : PATCH_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			// setStartCh(options.pipelineConfigFrom);
			// setEndCh(options.pipelineConfigTo);
		}
        
	};

	const showPatch = (patch,itemdate) => {
		// patch.itemdate=itemdate;
		patch.itemdate=moment.utc(itemdate).local().format(settingsdateformat);
		navigate(( '/patch-details'),{ state: { data: patch, path: 'patch' } });
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};

	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};


	// const from_datetimeobj=[];

	// if(startDate && startDate !=='1970-01-01'){
	// 	startDate.setHours(0,0,0);
	// 	let startdatetime=startdatetimeCoverter(startDate);
	// 	from_datetimeobj.push({startdatetime});

	// }

	// const to_datetimeobj=[];

	// if(endDate && endDate !=='1970-01-01'){

	// 	endDate.setHours(0,0,0);
	// 	// filterDate.setHours(parseFloat(filterEndTime));
	// 	let enddatetime=enddatetimeCoverter(endDate);
	// 	to_datetimeobj.push({enddatetime});

	// }

	


	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
		} else {
			console.log('Clear');
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.patchcontent1 ? langdata.patchcontent1:'Patch Coverage represents the percentage of number of chainages covered by a linewalker/NPV during their inspections performed using the Security Tracking System (STS) application. Patch Coverage details for different linewalkers/NPVs are displayed along with the total average percentage calculation.'}</p>
			<br />
			<p>{langdata && langdata.patchcontent1 ? langdata.patchcontent2:'By default, details from last 1 month till date are displayed. Filter options available to filter by allocation and date to adjust average patch coverage calculation.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={8}>
						<Row>

							<Space>
								<FileSearchOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
					
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.ReportSTSPatchCoverage ? langdata.ReportSTSPatchCoverage:'Report - STS Patch Coverage'}</Title>
						</Row>
					</Col>

					<Col style ={{justifyContent:'right', display:'flex'}} span={16} >
						<Row>
							<Col span={24}>
							

								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion:'Filter by region'}>
									<Space>
										<Select Item
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											// value={regionId}
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}

										/>
									</Space>
								</Tooltip>

								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Selectdate ? langdata.Selectdate:'Select date'}>

									<Space>
										<RangePicker onChange={onRangeChange} disabledDate={disabledDate} allowClear={false} format={settingsdateformat}
											defaultValue={[dayjs(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30)),dayjs(new Date())]}
											style={{ margin:'5px'}} />
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.patchreport ? langdata.patchreport:'Patch Coverage'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
					{/* </Space> */}
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.length > 0 ? (
							<Table columns={columns} dataSource={data}   scroll={{x: moment(endDate).format('YYYY-MM-DD')&& moment(endDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') ? 0:1300, }}
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>

				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}
				style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
					justifyContent:'center',
					display:'flex'
				}} 
				>
					<Col style={{
						alignItems:'left',
						justifyContent:'left',
						display:'flex',
						padding:'10px'
					}}  className="gutter-row" span={24}>
						<Button disabled={data && data.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download:'Download'}</Button>
					</Col>

				</Row>

			</Content>

			{/* Fetch and Download Modal Starts */}
			<Modal
				title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}
				centered
				destroyOnClose={true}
				open={showFetchAndDownloadModal}
				onOk={{closeShowFetchAndDownloadModal}}
				onCancel={closeShowFetchAndDownloadModal}
				footer={[
					
				]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>
							{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords:'Total number of records'} : {patch_data.patch_data.length}
						</Typography>
					</Col>
				</Row>

				{patch_data.patch_data && patch_data.patch_data.length> 0 ? (
					<BatchDownloader total={patch_data.patch_data && patch_data.patch_data.length} url={url}/>
				) : (
					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Col span={24}>
								<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound:'No records found.'}</Typography>
							</Col>
						</Row>
						<br/>
					</>
          
				)}
			</Modal>
			{/* Fetch and Download Modal Modal Ends */}
			<Spin spinning={patch_data.pending} fullscreen />

		</>
	);
}